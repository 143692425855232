<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="loader === true"
      variant="dark"
      opacity="0.85"
      blur="2px"
      rounded="sm"
      spinner-variant="primary"
      spinner-medium
    >
      <b-alert
        variant="danger"
        :show="alertErrors.length > 0"
        class="mb-0"
      >
        <div
          v-for="(row, index) in alertErrors"
          :key="index"
          class="alert-body"
        >
          <feather-icon

            icon="InfoIcon"
            class="mr-50"
          />
          {{ row }}
        </div>
      </b-alert>
      <div>
        <b-card
          aria-hidden="true"
          class="mb-0"
          title="Update Profile"
        >
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >

            <!-- User Info: Input Fields -->
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <b-row>

                <!-- Field: Username -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Username"
                    :rules="`required|alpha-num|unique_username:${useUserData.id}`"
                  >
                    <b-form-group

                      label="Username"
                      label-for="username"
                    >
                      <b-form-input
                        id="username"
                        v-model="useUserData.username"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Full Name -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Name"
                    rules="required"
                  >
                    <b-form-group
                      label="Name"
                      label-for="full-name"
                    >
                      <b-form-input
                        id="full-name"
                        v-model="useUserData.name"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Email -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Email"
                    :rules="`required|email|unique_email:${useUserData.id}`"
                  >
                    <b-form-group
                      label="Email"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="useUserData.email"
                        type="email"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Phone -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Phone"
                    rules="required"
                  >
                    <b-form-group
                      label="Phone"
                      label-for="phone"
                    >
                      <b-form-input
                        id="phone"
                        v-model="useUserData.phone"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Address -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Address"
                    rules="required"
                  >
                    <b-form-group
                      label="Address"
                      label-for="address"
                    >
                      <b-form-input
                        id="address"
                        v-model="useUserData.address"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- Action Buttons -->
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                {{ $t('DEBTIA_SAVE') }}
              </b-button>
            </b-form>

          </validation-observer>
        </b-card>
      </div>
      <hr>
      <div>
        <b-card
          aria-hidden="true"
          class="mb-0"
          title="Change Password"
        >
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >

            <!-- User Info: Input Fields -->
            <b-form
              @submit.prevent="handleSubmit(updatePassword)"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >

                  <b-form-group
                    label="Password"
                    label-for="Password"
                  >
                    <b-form-input
                      id="Password"
                      v-model="password"
                      trim
                      placeholder="*****"
                      type="password"
                      @input="validatePasswords"
                      @blur="validatePasswords"
                    />
                    <small
                      class="text-danger"
                      :v-show="showPasswordMsg === true"
                    >{{ passwordMsg }}</small>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >

                  <b-form-group
                    label="Confirm Password"
                    label-for="confirm-password"
                  >
                    <b-form-input
                      id="confirm-password"
                      v-model="confirmPassword"
                      trim
                      placeholder="*****"
                      type="password"
                      @input="validatePasswords()"
                      @blur="validatePasswords()"
                    />
                    <small
                      class="text-danger"
                      :v-show="showConfirmMsg === true"
                    >{{ confirmMsg }}</small>
                  </b-form-group>

                </b-col>
              </b-row>
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                {{ $t('DEBTIA_SAVE') }}
              </b-button>
            </b-form>
          </validation-observer>

        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard,
  BFormInvalidFeedback, BOverlay, BAlert,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import vSelect from 'vue-select'
// import { ref/* , onUnmounted  */ } from '@vue/composition-api'
import axios from '@axios'
// import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    // vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BOverlay,
    BAlert,
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(JSON.parse(localStorage.getItem('userData')).data)

    return {
      required,
      alphaNum,
      email,
      selected: [],
      alertErrors: [],
      useUserData: JSON.parse(localStorage.getItem('userData')).data,
      loader: false,
      refFormObserver,
      getValidationState,
      password: '',
      confirmPassword: '',
      confirmMsg: '',
      showConfirmMsg: false,
      passwordMsg: '',
      showPasswordMsg: false,
      passwordValidation: false,
    }
  },
  watch: {
    useUserData: {
      deep: true,
      handler(newValue) {
        console.log('Selected users changed', newValue.permissions)
      },
    },
  },
  methods: {
    validatePasswords() {
      if (this.password === '' && this.confirmPassword === '') {
        this.showPasswordMsg = true
        this.showConfirmMsg = true
        this.passwordValidation = false
        this.passwordMsg = 'Password field is required.'
        this.confirmMsg = 'Confirm Password field is required.'
      } else if (this.password === '' || this.confirmPassword === '') {
        if (this.password === '') {
          this.showPasswordMsg = true
          this.showConfirmMsg = false
          this.passwordValidation = false
          this.passwordMsg = 'Password field is required.'
          this.confirmMsg = ''
        }
        if (this.confirmPassword === '') {
          this.showConfirmMsg = true
          this.showPasswordMsg = false
          this.passwordValidation = false
          this.confirmMsg = 'Confirm Password field is required.'
          this.passwordMsg = ''
        }
      } else if (this.confirmPassword !== this.password) {
        this.showConfirmMsg = true
        this.showPasswordMsg = false
        this.passwordValidation = false
        this.confirmMsg = 'Confirm Password should match Password.'
      } else if (this.confirmPassword === this.password) {
        this.showConfirmMsg = false
        this.showPasswordMsg = false
        this.passwordValidation = true
        this.confirmMsg = ''
        this.passwordMsg = ''
      }
    },
    async onSubmit() {
      await axios.post('settings/update-profile', { ...this.useUserData })
        .then(response => {
          console.log('response', response.data.data)
          // const localData = JSON.parse(localStorage.getItem('userData'))
          localStorage.setItem('userData', JSON.stringify(response.data))
          // console.log(localData)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Profile Updated',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        }).catch(error => this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error,
          },
        }))
    },

    resetForm() {
      this.useUserData = { ...this.userData }
    },

    async updatePassword() {
      if (!this.passwordValidation) {
        if (this.passwordMsg === '' && this.password === '') {
          this.passwordMsg = 'Password field is required.'
        }
        if (this.confirmMsg === '' && this.confirmPassword === '') {
          this.confirmMsg = 'Confirm Password field is required.'
        }
        return
      }
      await axios.post('settings/update-password', { password: this.password })
        .then(response => {
          this.password = ''
          this.confirmPassword = ''
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Password Changed',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        }).catch(error => this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error,
          },
        }))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.spinner-border{
  width: 6rem;
  height: 6rem;
}
</style>
